import React from "react"

import { getAvatarUrl } from "./team"
import Hr from "components/elements/hr"
import Title from "components/elements/title"
import Svg from "components/svgs/svg"

import * as s from "./member.module.sass"

const Member = (props) => {
  const {
    member,
    index,
    count,
    previousName,
    nextName,
    onPrevious,
    onNext,
  } = props

  return [
    <div key="component" className={s.component}>
      <div className="container">
        <div className={s.body}>
          <aside className={s.side}>
            <img src={getAvatarUrl(member.name)} />
          </aside>

          <main className={s.main}>
            <Title parentClass={s.name}>{member.name}</Title>
            <p className={s.position}>{member.position}</p>
            <Hr parentClass={s.hr} />
            <p className={s.description}>{member.description}</p>
            <footer className={s.game}>
              <p>Favourite game</p>
              <p>{member.favouriteGame}</p>
            </footer>
          </main>
        </div>
      </div>
    </div>,
    <nav key="nav" className={s.nav}>
      <div className="container">
        <div className={s.controls}>
          <button className={s.previous} onClick={onPrevious}>
            <Svg parentClass={s.svg} id="chevronLeft" />
            <span>{previousName}</span>
          </button>
          <p className={s.count}>
            <strong>{index + 1}</strong>
            <em>/</em>
            <span>{count}</span>
          </p>
          <button className={s.next} onClick={onNext}>
            <span>{nextName}</span>
            <Svg parentClass={s.svg} id="chevronRight" />
          </button>
        </div>
      </div>
    </nav>,
  ]
}

export default Member
