import React from "react"
import ReactModal from "react-modal"

import * as s from "./modal.module.sass"

ReactModal.setAppElement("#___gatsby")

const Modal = (props) => {
  const { isOpen, onAfterOpen, onRequestClose, children } = props

  return (
    <ReactModal
      className={s.content}
      overlayClassName={s.overlay}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc={true}
    >
      {children}
      <button className={s.button} onClick={onRequestClose}>
        <span className={s.label}>Close</span>
      </button>
    </ReactModal>
  )
}

export default Modal
