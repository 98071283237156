import React, { Component } from "react"

import Heading from "components/elements/heading"
import Modal from "components/components/modal"
import Member from "components/team/member"

import cx from "classnames"
import * as s from "./team.module.sass"

const teamData = require("data/team.json")

const KEYS = {
  left: { key: "ArrowLeft", code: 37 },
  right: { key: "ArrowRight", code: 39 },
}

// Dimensions of center to avoid
const CENTER_WIDTH = 600;
const CENTER_HEIGHT = 400;
const X_MIN = 0.1;
const X_MAX = 0.9;
const Y_MIN = 0;
const Y_MAX = 0.8;

export const getAvatarUrl = (memberName) =>
  `${process.env.SITE_URL}/images/team/${memberName}.png`

const easingEquations = {
  easeOutSine: function (pos) {
    return Math.sin(pos * (Math.PI / 2))
  },
  easeInOutSine: function (pos) {
    return -0.5 * (Math.cos(Math.PI * pos) - 1)
  },
  easeInOutQuint: function (pos) {
    if ((pos /= 0.5) < 1) {
      return 0.5 * Math.pow(pos, 5)
    }
    return 0.5 * (Math.pow(pos - 2, 5) + 2)
  },
}

class Team extends Component {
  ticking = false
  resizeTimer
  hasLaunched = false

  constructor(props) {
    super(props)
    this.state = {
      chosenMemberIndex: -1,
      lastIndex: teamData.length - 1,
    }
    this.componentRef = React.createRef()
    this.itemsRef = []
  }

  // Lifecycle
  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
    window.addEventListener("keydown", this.handleKeydown, false)
    this.positionEverything()
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
    window.removeEventListener("keydown", this.handleKeydown)
  }

  // Calculations
  getRandomArbitrary = (min, max) => {
    return Math.random() * (max - min) + min
  }

  randomPercentage = (range) => {
    const min = range[0];
    const max = range[1];
    return this.getRandomArbitrary(min, max) * 100
  }

  randomPosition = (width, height) => {
    // We only need either the X or the Y to be in the safe zone
    // (meaning, not in the center)

    // First, calculate safe X range
    const safeX = (width - CENTER_WIDTH) / 2;
    const safeXPercentage = Math.round((safeX / width) * 100) / 100;

    // Second, get random X
    let finalX = this.randomPercentage([X_MIN, X_MAX]);
    let finalY = 0;

    // Third, check if random X is too close to the middle
    // If so, the Y must be in the safe zone (outside the center)
    if (
        finalX > (safeXPercentage * 100)
        &&
        finalX < (1 - safeXPercentage) * 100
      ) {
      const safeY = (height - CENTER_HEIGHT) / 2;
      const safeYPercentage = Math.round((safeY / height) * 100) / 100;
      const oneOrZeroY = (Math.random() > 0.5) ? 1 : 0;
      const rangesY = [[Y_MIN, safeYPercentage], [1 - safeYPercentage, Y_MAX]];
      finalY = this.randomPercentage(rangesY[oneOrZeroY]);
    } else {
      finalY = this.randomPercentage([Y_MIN, Y_MAX]);
    }

    return [finalX, finalY]
  }

  // Algorithm
  distance = (a, b) => {
    const dx = a[0] - b[0]
    const dy = a[1] - b[1]
    return Math.sqrt(dx * dx + dy * dy)
  }

  findClosestPosition = (allPositions, newPosition) => {
    let closestPoint = [0, 0]
    let closestDistance = Infinity

    allPositions.forEach((position) => {
      const distanceFromNewPosition = this.distance(position, newPosition)

      if (distanceFromNewPosition < closestDistance) {
        closestPoint = position
        closestDistance = distanceFromNewPosition
      }
    })

    return closestPoint
  }

  findBestCandidate = (width, height, allPositions, numCandidates = 100) => {
    let bestCandidate = 0
    let bestDistance = 0

    for (let i = 0; i < numCandidates; ++i) {
      let c = this.randomPosition(width, height)
      let d = this.distance(this.findClosestPosition(allPositions, c), c)

      if (d > bestDistance) {
        bestDistance = d
        bestCandidate = c
      }
    }

    return bestCandidate
  }

  // Positioning
  positionToCSS = (position) => {
    return {
      top: `${position[0]}%`,
      left: `${position[1]}%`,
    }
  }

  positionDot = (el, transforms) => {
    const speed = 1000
    const targetX = transforms[0]
    const targetY = transforms[1]
    const zIndex = transforms[2]
    let currentX = this.hasLaunched ? parseInt(el.dataset.x) : 0
    let currentY = this.hasLaunched ? parseInt(el.dataset.y) : 0
    el.style.zIndex = zIndex;

    let currentTime = 0

    // min time .1, max time .8 seconds
    const time = Math.max(1, Math.min(Math.abs(currentX - targetX) / speed, 2))

    function tick() {
      currentTime += 1 / 60

      const p = currentTime / time
      const t = easingEquations["easeOutSine"](p)

      if (p < 1) {
        window.requestAnimationFrame(tick)
        const tempX = currentX + (targetX - currentX) * t
        const tempY = currentY + (targetY - currentY) * t
        el.style.transform = `translate3d(${tempX}px, ${tempY}px, 0)`
      } else {
        el.style.transform = `translate3d(${targetX}px, ${targetY}px, 0)`
        el.dataset.x = targetX
        el.dataset.y = targetY
      }
    }

    // call it once to get started
    tick()
  }

  percentagesToTransforms = (allPositions, width, height) => {
    return allPositions.map((position) => {
      const x = (position[0] / 100) * width - width / 2
      const y = (position[1] / 100) * height - height / 2
      const z = Math.round(position[0]) + Math.round(position[1]);
      return [x, y, z]
    })
  }

  positionEverything = () => {
    const {
      offsetWidth: width,
      offsetHeight: height,
    } = this.componentRef.current
    let allPositions = [this.randomPosition(width, height)]

    // Start at 1 because the first position is already set
    for (let i = 1; i < this.itemsRef.length; ++i) {
      const bestCandidate = this.findBestCandidate(width, height, allPositions)
      allPositions.push(bestCandidate)
    }

    const transforms = this.percentagesToTransforms(allPositions, width, height)

    this.itemsRef.forEach((el, index) => {
      this.positionDot(el, transforms[index])
    })

    this.hasLaunched = true
  }

  // Events
  handleResize = () => {
    const self = this

    window.clearTimeout(this.resizeTimer)

    this.resizeTimer = window.setTimeout(function () {
      self.doneResizing()
    }, 250)
  }

  doneResizing = () => {
    this.positionEverything()
  }

  handleClose = () => {
    this.setState({
      chosenMemberIndex: -1,
    })
  }

  showMember = (memberIndex) => {
    this.setState({
      chosenMemberIndex: memberIndex,
    })
  }

  handleKeydown = (e) => {
    const { chosenMemberIndex, lastIndex } = this.state

    // Bail if the modal is not open yet
    if (chosenMemberIndex === -1) {
      return
    }

    const nextIndex =
      chosenMemberIndex === lastIndex ? 0 : chosenMemberIndex + 1

    const previousIndex =
      chosenMemberIndex === 0 ? lastIndex : chosenMemberIndex - 1

    switch (e.keyCode) {
      case KEYS["right"].code:
        if (nextIndex === -1) {
          break
        }
        this.showMember(nextIndex)
        break
      case KEYS["left"].code:
        if (previousIndex === -1) {
          break
        }
        this.showMember(previousIndex)
        break
      default:
    }
  }

  render() {
    const { parentClass } = this.props
    const { chosenMemberIndex, lastIndex } = this.state

    const componentClass = cx({
      [s.component]: true,
      [parentClass]: parentClass,
    })

    const nextIndex =
      chosenMemberIndex > -1
        ? chosenMemberIndex === lastIndex
          ? 0
          : chosenMemberIndex + 1
        : -1
    const nextName = nextIndex > -1 ? teamData[nextIndex].name : ""

    const previousIndex =
      chosenMemberIndex > -1
        ? chosenMemberIndex === 0
          ? lastIndex
          : chosenMemberIndex - 1
        : -1
    const previousName = previousIndex > -1 ? teamData[previousIndex].name : ""

    const chosenMember =
      chosenMemberIndex > -1 ? teamData[chosenMemberIndex] : null

    return (
      <div className={componentClass} ref={this.componentRef}>
        <Heading
          parentClass={s.heading}
          surtitle="Power to the people"
          title="Meet the team"
        />

        <div className={s.members}>
          {teamData.map((member, index) => {
            const src = getAvatarUrl(member.name)
            return (
              <div
                key={member.id}
                className={s.member}
                ref={(ref) => (this.itemsRef[index] = ref)}
                onClick={() => this.showMember(index)}
              >
                <div className={s.body}>
                  <div className={s.circle}>
                    <figure className={s.avatar}>
                      <img src={src} />
                    </figure>
                  </div>

                  <div className={s.info}>
                    <div>
                      <p className={s.name}>
                        <span>{member.name}</span>
                      </p>
                      <p className={s.position}>
                        <span>{member.position}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <Modal
          isOpen={chosenMember ? true : false}
          onRequestClose={this.handleClose}
        >
          {chosenMember && (
            <Member
              member={chosenMember}
              count={teamData.length}
              index={chosenMemberIndex}
              previousName={previousName}
              nextName={nextName}
              onNext={() => this.showMember(nextIndex)}
              onPrevious={() => this.showMember(previousIndex)}
            />
          )}
        </Modal>
      </div>
    )
  }
}

export default Team
