import React from "react"
import { Helmet } from "react-helmet"

import Layout from "layouts/default"
import Header from "components/components/header"
import Team from "components/team/team"

import * as s from "./team.module.sass"

const TeamPage = () => (
  <Layout parentClass={s.layout}>
    <Helmet>
      <title>Netspeak Games Team</title>
    </Helmet>
    <Header parentClass={s.header} />
    <Team parentClass={s.team} />
  </Layout>
)

export default TeamPage
