// extracted by mini-css-extract-plugin
export var count = "member-module--count--1seU7";
export var previous = "member-module--previous--2z2iD";
export var next = "member-module--next--3TrVJ";
export var component = "member-module--component--3_fau";
export var side = "member-module--side--3fNBy";
export var name = "member-module--name--11N5J";
export var position = "member-module--position--1c2VC";
export var hr = "member-module--hr--3k2Hn";
export var game = "member-module--game--s7Xqz";
export var controls = "member-module--controls--3o5yH";
export var nav = "member-module--nav--14cUc";
export var svg = "member-module--svg--zD56a";
export var body = "member-module--body--UN6pu";
export var main = "member-module--main--2s4ZA";
export var description = "member-module--description--30Gyz";